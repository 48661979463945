<template>
    <div class="common-comp-count">
        <div class="btn">
            <div class="down" @click="down"> - </div>
        </div>
        <div class="number">{{this.num}}</div>
        <div class="btn">
            <div class="up" @click="up"> + </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Count',
    props: {
        count: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 10000
        }
    },
    model: {
      prop: 'count',
      event: 'change'
    },
    data() {
        return {
            num: this.count
        }
    },
    watch: {
        num(v) {
            this.$emit('change', v)
        },
        count(v) {
            this.num = v
        }
    },
    methods: {
        up() {
            if (this.num === this.max) {
                return
            }
            this.num += 1
        },
        down() {
            if (this.num === 0) {
                return
            }
            this.num -= 1
        }
    }
}
</script>

<style lang="less">
.common-comp-count {
    display: flex;
    height: 36px;
    box-sizing: border-box;
    .number {
        background: #fff;
        font-family: ChalkboardSEBold;
        text-align: center;
        line-height: 32px;
        font-size: 18px;
        flex: 1;
        color: #000;
        margin: 0 5px;
        padding: 0 12px;
        border-radius: 2px;
    }
    .btn {
        display: flex;
        width: 36px;
        div {
            display: flex;
            justify-content: center;
            cursor: pointer;
            background-color: #2C99F1;
            flex: 1;
            border-radius: 5px;
            overflow: hidden;
            font-size: 28px;
            line-height: 30px;
        }
    }
}
</style>
